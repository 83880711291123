import Axios, { AxiosResponse } from "axios";
import { useAtom, useSetAtom } from "jotai";
import { environment } from "../../environment";
import { errorCatcher, useSnackbar } from "../../hooks";
import { useLogin } from "../login";
import { PaginatedResponseType } from "../../globals/types";
import { 
    IProduct, 
    IGetDesignsQuery, 
    ICreateDesignRequest, 
    ICreateSampleRequest, 
    IApplyPatternRequest, 
    ICreateHeaderRequest, 
    ICreateStoreProductRequest, 
    productsAtom, 
    updateMockupAtom, 
    updateSampleAtom, 
    singleProductAtom, 
    singleProductLoadingAtom, 
    storeProductCreatingLoadingAtom 
} from "./types";
import { IShopifyProduct } from "../shopify";

type SetProductType = (payload: PaginatedResponseType<IProduct>) => void;
type FetchProductsType = (body: IGetDesignsQuery) => void;
type FetchProductType = (design_id: number) => void;
type AddProductType = (form: ICreateDesignRequest) => Promise<boolean | null>;
type GetProductsFromShopify = (value: string, brand?: string) => Promise<{name: string; id: string;}[] | null>;
type CreateSampleType = (body: ICreateSampleRequest) => Promise<IProduct | null>;
type SetMockupType = (body: IApplyPatternRequest) => Promise<null>;
type CreateHeaderType = (body: ICreateHeaderRequest) => Promise<null>;
type ToggleIsStaticType = (design_id: number, current: boolean) => Promise<IProduct | null>;
type DeleteProduct = (design_id: number, brand_id: number) => Promise<IProduct | null>;
type CreateStoreProduct = (createRequest: ICreateStoreProductRequest) => Promise<IShopifyProduct | null>;
type ResolveHalfStep = (form: ICreateDesignRequest, file: File) => Promise<boolean | null>;

export type ProductKeyFunctions = {
    get: FetchProductsType;
    getProduct: FetchProductType;
    add: AddProductType;
    getProducts: GetProductsFromShopify;
    createSample: CreateSampleType;
    setMockup: SetMockupType;
    createHeader: CreateHeaderType;
    toggleIsFixedScale: ToggleIsStaticType;
    deleteProduct: DeleteProduct;
    createStoreProduct: CreateStoreProduct;
    resolveHalfStep: ResolveHalfStep;
}

type UseProducts = [
    PaginatedResponseType<IProduct>,
    IProduct | null,
    SetProductType,
    ProductKeyFunctions
];

export const useProducts = (): UseProducts => {
    const [products, setProducts] = useAtom(productsAtom);
    const [, setMockupUpdating] = useAtom(updateMockupAtom);
    const [, setSampleUpdating] = useAtom(updateSampleAtom);

    const [singleProduct, setSingleProduct] = useAtom(singleProductAtom);
    const setSingleProductLoading = useSetAtom(singleProductLoadingAtom);

    const setStoreProductCreatingLoading = useSetAtom(storeProductCreatingLoadingAtom);

    const [,setSnackbar] = useSnackbar();
    const [,authToken, loginFunctions] = useLogin();

    const getProducts = (body: IGetDesignsQuery) => {
        const fetchProducts = async (setter: SetProductType): Promise<void> => {
            const results = await Axios.get(`${environment.productsUrl}?brand_id=${body.brand_id}&page_count=${body.page_count}&page=${body.page}${body.has_sample !== undefined ? `&has_sample=${body.has_sample}` : ''}${body.queryString !== null ? `&queryString=${encodeURIComponent(body.queryString as string)}` : ''}`, {
                headers: {
                    'X-Wallmates-Auth': authToken
                }
            }).catch((err: any) => errorCatcher(err, loginFunctions.logout));
            if (results) {
                setter(results.data);
            } else {
                setSnackbar({
                    show: true,
                    snackbarLevel: 'error',
                    text: 'There was an error retrieving products. Please try again later.'
                });
            }
        }
        fetchProducts(setProducts);
    }

    const add = async (form: ICreateDesignRequest): Promise<boolean | null> => {
        const insertResponse: AxiosResponse<boolean> | void = await Axios.post(`${environment.queuesUrl}/image-processor`, form, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (insertResponse) {
            setSnackbar({
                show: true,
                snackbarLevel: 'info',
                text: 'Product processing initiated. Please check the notification tab for processing progress.'
            });
            return insertResponse.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: `There was an error processing product: ${form['product_id']}. Please try again later.`
            });
            return null;
        }
    }

    const getProductsFromShopify = async (value: string, brand = 'wall-blush'): Promise<{ name: string; id: string; }[] | null> => {
        if (brand === '') brand = 'wall-blush';
        if (value === '') return [];
        const getShopifyProducts: AxiosResponse<{ name: string; id: string; }[]> | void = await Axios.get(`${environment.productsUrl}/shopify-products?brand_name=${brand}&query=${value}`, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));
        if (getShopifyProducts) {
            return getShopifyProducts.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error retrieving products from shopify. Please try again later.'
            });
            return null;
        }
    }

    const createSample = async (createBody: ICreateSampleRequest): Promise<IProduct | null> => {
        setSampleUpdating(true);
        const createResponse: AxiosResponse<IProduct | null> | void = await Axios.post(`${environment.queuesUrl}/image-processor`, createBody, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));
        if (createResponse) {
            setSampleUpdating(false);
            setSnackbar({
                show: true,
                snackbarLevel: 'info',
                text: 'Sample Added To Sample Creation Queue'
            });
            return createResponse.data;
        } else {
            setSampleUpdating(false);
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error in creating the sample. Please try again later.'
            });
            return null;
        }
    }

    const setMockup = async (body: IApplyPatternRequest): Promise<null> => {
        setMockupUpdating(true);
        const setMockup: AxiosResponse<null> | void = await Axios.post(`${environment.queuesUrl}/image-processor`, body, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));
        if (setMockup) {
            setMockupUpdating(false);
            setSnackbar({
                show: true,
                snackbarLevel: 'info',
                text: 'Mockups Added To Mockup Creation Queue.'
            });
            return setMockup.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error in creating the mockup. Please try again later.'
            });
            setMockupUpdating(false);
            return null;
        }
    }

    const createHeader = async (body: ICreateHeaderRequest): Promise<null> => {
        const setHeader: AxiosResponse<null> | void = await Axios.post(`${environment.queuesUrl}/image-processor`, body, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));
        if (setHeader) {
            setSnackbar({
                show: true,
                snackbarLevel: 'info',
                text: 'Header Added To Header Creation Queue.'
            });
            return setHeader.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error in creating the header. Please try again later.'
            });
            return null;
        }
    }

    const getProduct = async (design_id: number) => {
        setSingleProductLoading(true);
        const getSingleProduct: AxiosResponse<IProduct> | void = await Axios.get(`${environment.productsUrl}/${design_id}`, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (getSingleProduct) {
            setSingleProductLoading(false);
            setSingleProduct(getSingleProduct.data);
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error in getting the product. Please try again later.'
            });
        }
    }

    const toggleIsFixedScale = async (design_id: number, current: boolean) => {
        const getSingleProduct: AxiosResponse<IProduct> | void = await Axios.put(`${environment.productsUrl}/toggle-fixed-scale`, {
            design_id,
            current
        }, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (getSingleProduct) {
            return getSingleProduct.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error in getting the product. Please try again later.'
            });
            return null;
        }
    }

    const deleteProduct = async (design_id: number, brand_id: number) => {
        const deleteProduct: AxiosResponse<IProduct> | void = await Axios.delete(`${environment.productsUrl}/${design_id}?brand_id=${brand_id}`, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (deleteProduct) {
            setProducts({
                count: products.count - 1,
                hasBefore: products.hasBefore,
                hasNext: products.hasNext,
                results: products.results.filter((product: IProduct) => {
                    if (product.id === design_id) return false;
                    return true;
                })
            });
            setSingleProduct(null);
            return deleteProduct.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error in delete product. Please try again later.'
            });
            return null;
        }
    }

    const createStoreProduct = async (body: ICreateStoreProductRequest): Promise<IShopifyProduct | null> => {
        setStoreProductCreatingLoading(true);
        const storeProduct: AxiosResponse<IShopifyProduct | null> | void = await Axios.post(`${environment.productsUrl}/create`, body, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (storeProduct) {
            setStoreProductCreatingLoading(false);
            return storeProduct.data;
        } else {
            setStoreProductCreatingLoading(false);
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error in creating store product. Please try again later.'
            });
            return null;
        }
    }

    const resolveHalfStep = async (body: ICreateDesignRequest, file: File): Promise<boolean | null> => {
        const form = new FormData();
        form.append('half-step-file', file);
        form.append('product_id', body.product_id.toString());
        form.append('brand_id', body.brand_id.toString());

        const storeProduct: AxiosResponse<boolean | null> | void = await Axios.post(`${environment.productsUrl}/half-step-repair`, form, {
            headers: {
                'X-Wallmates-Auth': authToken,
                'Content-Type': 'x-www-form-urlencoded'
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (storeProduct) {
            return storeProduct.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error in creating store product. Please try again later.'
            });
            return null;
        }
    }

    const keyFunctions: ProductKeyFunctions = {
        add,
        getProduct,
        get: getProducts,
        getProducts: getProductsFromShopify,
        createSample,
        setMockup,
        createHeader,
        toggleIsFixedScale,
        deleteProduct,
        createStoreProduct,
        resolveHalfStep
    }

    return [
        products,
        singleProduct,
        setProducts,
        keyFunctions
    ];
}
