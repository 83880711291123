import { atom } from "jotai";
import { IOrderDTO } from "../order";

export const currentWeightScanItemAtom = atom<IWeightScanResponse | null>(null);
export const weightScanItemLoadingAtom = atom<boolean>(false);
export const weighQueueAtom = atom<IWeighQueue[]>([]);
export const currentScanItemAtom = atom<IScanResponse | null>(null);
export const scanLoadingAtom = atom<boolean>(false);
export const lastScanValueAtom = atom<string>('');

export interface IScanResponse {
    order: IOrderDTO;
    zpl: string;
    lineItems: IOrderLineItemDTO[];
    orderConsumables: IOrderConsumables;
    scanValue: string;
}

export interface IOrderConsumables {
    id: number;
    created_at: string;
    order_id: number;
    small_box: number;
    large_box: number;
    paste: number;
    squeegee: number;
}

export interface IOrderLineItemDTO {
    id: number;
    print_range: string;
    line_item_jdml: string;
    design: {
        id: number;
        design_name: string;
        shopify_id: string;
        collection: {
            name: string;
        };
        pat_19_file: string;
        metadata: {
            id: number;
            material: {
                category: string;
            }
        }
        sample: string;
        tiled: string;
        twelvth: string;
        mockup: string;
        header: string;
    };
    consumable: {
        id: number;
        name: string;
        image: string;
        sku: string;
    };
    material: {
        id: number;
        category: string;
    };
    order_id: number;
    height: string;
    width: number;
    droppable: boolean;
    jdml_dropped: boolean;
    line_item_scans: IOrderLineItemScanDTO[];
    print_id: string;
    line_item_issues: IOrderLineItemIssueDTO[];
    line_item_reprints: IOrderLineItemReprintsDTO[];
    name?: string;
}

export interface IOrderLineItemReprintsDTO {
    id: number;
    scan_time: string;
    scanned: boolean;
    bin: string;
    reprint_jdml: string;
    created_at: string;
    range_start: number;
    range_end: number;
    print_id: string;
}

export interface IOrderLineItemIssueDTO {
    id: number;
    employee: {
        id: number;
        first: string;
        last: string;
    };
    issue: {
        id: number;
        value: string;
    };
    created_at: string;
}

export interface IOrderLineItemScanDTO {
    id: number;
    scan_time: string;
    scanned: boolean;
    split_index: number;
    bin: string;
    print_range_start: number;
    print_range_end: number;
}

export interface IScanRequest {
    scanValue: string;
    isUnscan: boolean;
    loadedOrder: number | null;
}

export interface IManualSearch {
    query: string;
}

export interface IWeightScanResponse {
    [key: string]: any;
}

export type QueueStatus = 'unprocessed' | 'processing' | 'processed';

export interface IWeighQueue {
    id?: number;
    created_at: string;
    box_weights: IBoxWeight;
    process_status: QueueStatus;
}

export interface IBoxWeight {
    id?: number;
    created_at: string;
    box_type: 'large' | 'medium' | 'small';
    weight_pounds: number;
    weight_ounces: number;
    order: {
        id: number;
        line_items: {
            id: number;
            scan: {
                id: number;
                scanned: boolean;
            }[]
        }[]
    }
    tag_text: string;
}