import { atom } from "jotai";
import { IScanRequest } from "../scanner";

export const printerAtom = atom<IPrinter[]>([]);
export const getPrintersLoadingAtom = atom<boolean>(false);
export const selectedPrinterAtom = atom<IPrinter>({} as IPrinter);
export const lineItemIssuesAtom = atom<ILineItemIssues[]>([]);

export interface IPrinter {
    id: number;
    print_node_id: number;
    status: string;
    station_name: string;
}

export interface ILineItemIssues {
    id: number;
    value: string;
    action: string;
    is_active: boolean;
}

export interface ISubmitLineItemIssueRequest {
    issue_id: number;
    user_id: number;
    scan_request: IScanRequest;
    material_id: number;
    brand_id: number;
}