import { atom } from "jotai";
import { PaginatedResponseType } from "../../globals/types";

export const productsAtom = atom<PaginatedResponseType<IProduct>>({ count: 0, hasBefore: false, hasNext: false, results: [] } as PaginatedResponseType<IProduct>);
export const brandIdAtom = atom<number>(1);
export const updateMockupAtom = atom<boolean>(false);
export const updateSampleAtom = atom<boolean>(false);
export const storeProductCreatingLoadingAtom = atom<boolean>(false);
export const singleProductAtom = atom<IProduct | null>(null);
export const singleProductLoadingAtom = atom<boolean>(false);

export interface IProduct {
    id?: number;
    design_name: string;
    shopify_id: number;
    collection: number;
    panel_code: string;
    design_description: string;
    shopify_link: string;
    tags: string;
    art_type: number;
    pat_19_file: string;
    brand_id: number;
    has_sample: boolean;
    has_customized_mockup: boolean;
    selected_mockup_id: number;
    sample: string;
    tiled: string;
    twelvth: string;
    mockup: string;
    header: string;
    is_fixed_scale: boolean;
}

export interface IApplyPatternRequest {
    brand_id: number;
    product_id: string;
    mockup_ids: string;
    store_id: string;
}

export interface ICreateDesignRequest {
    brand: string;
    product_id: number;
    art_type: string;
    brand_id: number;
}

export interface IHeaderDesign {
    id?: number;
    design_name: string;
    header_design_template_id: number;
    brand_id: number;
    lead_rpe: string;
    mid_rpe: string;
    end_rpe: string;
    bucket_folder: string;
    header_jdml: string;
    design_id?: number;
}

export interface ICreateSampleRequest {
    coordinates: {
        x: number;
        y: number;
        width: number;
        height: number;
    };
    design_id: number;
    brand_id: number;
}

export interface ICreateHeaderRequest {
    brand_id: number;
    product_id: number;
    generated_mockup_id: number;
}

export interface IGetDesignsQuery {
    page_count: number;
    page: number;
    queryString: string | null;
    brand_id: number;
    has_sample?: boolean;
}

export interface ICreateStoreProductRequest {
    product_name: string;
    template_id: number;
    roundBy: string;
    brand_id: number;
    tags: string;
    collection: string;
}