import React from "react";
import { useBeforeUnload, useBlocker } from "react-router-dom";

export const usePrompt = (message: any, beforeUnload: boolean): any  => {

  const blocker = useBlocker(
    React.useCallback(
      () => (typeof message === "string" ? !window.confirm(message) : false),
      [message]
    )
  );
  const prevState = React.useRef(blocker.state);

  React.useEffect(() => {
    if (blocker.state === "blocked") {
      blocker.reset();
    }
    prevState.current = blocker.state;
  }, [blocker]);

  useBeforeUnload(
    React.useCallback(
      (event) => {
        if (beforeUnload && typeof message === "string") {
          event.preventDefault();
          event.returnValue = message;
        }
      },
      [message, beforeUnload]
    ),
    { capture: true }
  );
}

export const Prompt = ({ when, message, beforeUnload }: { when: boolean, message: string, beforeUnload: boolean }) => {
  usePrompt(when ? message : false, beforeUnload);
  return null;
}