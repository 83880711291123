import { atom } from "jotai";

export const pricingTemplatesAtom = atom<IPricingTemplate[]>([]);
export const populatedPricingTemplatesAtom = atom<IPopulatedPricingTemplate[]>([]);
export const pricingTemplatesLoadingAtom = atom<boolean>(false);
export const internalPricingAtom = atom<IBrandPricing | null>(null);

export interface IPricingTemplate {
    id?: number;
    created_at: string;
    brand_id: number;
    brand_pricing_id: number;
    name: string;
}

export interface IPopulatedPricingTemplate {
    id?: number;
    created_at: string;
    brand_id: number;
    brand_pricing: IBrandPricing;
    name: string;
}

export interface IInsertPricingTemplateRequest {
    brand_id: number;
    brand_pricing: IBrandPricing;
    name: string;
}

export interface IBrandPricing {
    id?: number;
    created_at?: string;
    traditional_sqft: number;
    traditional_sample: number;
    traditional_shipping?: number;
    traditional_box?: number;
    peel_sqft: number;
    peel_sample: number;
    peel_shipping?: number;
    peel_box?: number;
    sample_shipping?: number;
    sample_envelope?: number;
}